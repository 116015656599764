import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { RequestErrorType } from '@enums/request-error-type.enum';
import { NotificationsService } from './notifications.service';

/**
 * Service for handling error notifications.
 */
@Injectable({ providedIn: 'root' })
export class ErrorService {
  /**
   * Service for handling error notifications.
   */
  private readonly notificationsService = inject(NotificationsService);

  /**
   * Throws an error if the response status is not 401.
   * Otherwise, it handles the error.
   *
   * @param response - The HTTP error response.
   */
  throwError(response: HttpErrorResponse): void {
    const { status } = response;
    if (status === 401) return;

    this.handleError(response);
  }

  /**
   * Handles the error response from an HTTP request.
   * @param response - The HTTP error response.
   */
  handleError(response: HttpErrorResponse): void {
    const { error, details } = response.error;

    if (Object.values(RequestErrorType).includes(error)) {
      this.handleErrorNotification(error);
    } else {
      this.handleErrorNotification(RequestErrorType.SOMETHING_WENT_WRONG);
      console.error('🚀 ~ error:', details);
    }
  }

  /**
   * Handles the error notification based on the provided error type.
   * @param type - The type of the request error.
   */
  handleErrorNotification(type: RequestErrorType): void {
    this.notificationsService.error(`Errors.${type}`);
  }
}
