/**
 * Enum representing the types of request errors.
 */
export enum RequestErrorType {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  QUERY_FAILED = 'Query faild',
  CODE_EXPIRED = 'CODE_EXPIRED',
  INVALID_SMS_CODE = 'INVALID_SMS_CODE',
  TOO_MANY_ATTEMPTS_FOR_PASSWORD_RECOVERY = 'TOO_MANY_ATTEMPTS_FOR_PASSWORD_RECOVERY',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  PHONE_NUMBER_ALREADY_EXISTS = 'PHONE_NUMBER_ALREADY_EXISTS',
  POPUP_CLOSED_BY_USER = 'POPUP_CLOSED_BY_USER',
  POINTS_CANNOT_BE_SEND = 'POINTS_CANNOT_BE_SEND',
  INVITING_YOURSELF = 'INVITING_YOURSELF',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVITATION_ALREADY_SENT = 'INVITATION_ALREADY_SENT',
  ALREADY_FRIENDS = 'ALREADY_FRIENDS',
  INVALID_OLD_PASSWORD = 'INVALID_OLD_PASSWORD',
  INVALID_VOUCHER_CODE = 'INVALID_VOUCHER_CODE',
  VOUCHER_USED = 'VOUCHER_USED',
}

/**
 * Enum representing the context messages for request errors.
 */
export enum RequestErrorContextMessage {
  NO_USER_WITH_SUCH_PHONE_NUMBER = 'No user with such phone number',
  NO_USER_WITH_SUCH_EMAIL_FOUND = 'No user with such email found',
  YOU_CANT_INVITE_YOURSELF = "You can't invite yourself",
  YOU_ALREADY_BEFRIENT_THAT_USER = 'You already befriended that user',
  PHONE_ALREADY_EXISTS = 'Wartość "phone" już istnieje.',
  INVITATION_ALREADY_SENT = 'INVITATION_ALREADY_SENT',
}
